import * as React from "react"
import * as PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"

import Image from "../interfaces/image.interface"

export interface LogoProps {
  logo: Image
}

const Logo = ({ logo }: LogoProps) => (
  <div id="logo">
    <a href="/#banner">
      <GatsbyImage
        image={logo?.localFile.childImageSharp.gatsbyImageData}
        alt="Landing Page"
        id="banner-logo"
      />
    </a>
  </div>
)

Logo.propTypes = {
  logo: PropTypes.object.isRequired
}

export default Logo
