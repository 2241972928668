import * as React from "react"
import * as PropTypes from "prop-types"

import Logo, {LogoProps} from './logo';
import Nav, {NavProps} from './nav';

interface HeaderProps extends LogoProps, NavProps {}

const Header = ({logo, navigation}: HeaderProps) => (
  <header id="banner" className="scrollto clearfix" data-enllax-ratio=".5">
    <div id="header" className="nav-collapse">
      <div className="row clearfix">
        <div className="col-1">
          <Logo
            logo={logo}
          />
          <Nav navigation={navigation}/>
        </div>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  logo: PropTypes.object.isRequired,
  navigation: PropTypes.array.isRequired
}

export default Header
