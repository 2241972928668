import * as React from "react"
import * as PropTypes from "prop-types"

export interface FooterProps {
  copyrightText?: string
}

const Footer = ({ copyrightText }: FooterProps) => (
  <footer id="landing-footer" className="clearfix">
    <div className="row clearfix">
      <p id="copyright" className="col-2">© {new Date().getFullYear()} {copyrightText}</p>
    </div>
  </footer>
);

Footer.propTypes = {
  copyrightText: PropTypes.string
}

Footer.defaultProps = {
  copyrightText: ''
}

export default Footer
