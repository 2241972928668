/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import * as PropTypes from "prop-types"
import {graphql, useStaticQuery} from "gatsby";

import Header from "./header"
import Footer from "./footer";
import ScrollUp from './scrollUp';

const Layout: React.FC = ({ children }) => {
    const query = graphql`
    query {
      strapiNavigation {
        Navigation {
          title,
          href
        }
      }
      strapiHomepage {
        logo {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                  layout: CONSTRAINED,
                  placeholder: TRACED_SVG,
                  formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        copyright
      }
    }
  `;

    const {strapiHomepage, strapiNavigation} = useStaticQuery(query);

    return (
      <div id="wrapper">
          <Header logo={strapiHomepage.logo} navigation={strapiNavigation.Navigation}/>
          <main id="content">{children}</main>
          <Footer copyrightText={strapiHomepage.copyright} />
          <ScrollUp />
      </div>
    )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
