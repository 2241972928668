import * as React from "react"
import * as PropTypes from "prop-types"

export interface NavProps {
  navigation: {
    title: string,
    href: string
  }[]
}

const Nav = ({navigation}: NavProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
  <>
    <nav id="nav-main">
      <ul>
        {navigation.map(({title, href}, index) => (
          <li key={index}>
            <a href={href}>{title}</a>
          </li>
        ))}
      </ul>
    </nav>

    <div id="nav-trigger" onClick={() => setIsOpen(!isOpen)}>
      <span className={isOpen ? 'open' : null} />
    </div>
    <nav id="nav-mobile">
      <ul className={isOpen? 'display' : null}>
        {navigation.map(({title, href}, index) => (
          <li key={index}>
            <a href={href} onClick={() => setIsOpen(!isOpen)}>{title}</a>
          </li>
        ))}
      </ul>
    </nav>
  </>
  )
}

Nav.propTypes = {
  navigation: PropTypes.array.isRequired
}

export default Nav
