import * as React from "react"
import * as PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image";

import Image from "../interfaces/image.interface"

export interface MainProps {
  heroImage?: Image,
  headline: string,
  description: string
}

const Main = ({ heroImage, headline, description }: MainProps) => (
  <section>
    <div id="banner-content" className="row clearfix no-bottom-margin">
      <div className="clearfix no-margin wow fadeInUp bannerimg" data-wow-delay="0.5s">
        {heroImage &&
          <GatsbyImage
            image={heroImage?.localFile.childImageSharp.gatsbyImageData}
            alt="Hero Image"
          />
        }
      </div>
      <div className="col-2-3">
        <div className="section-heading">
          <h1>{headline}</h1>
          <div dangerouslySetInnerHTML={{__html: description}} />
        </div>
      </div>
    </div>
  </section>
);

Main.propTypes = {
  img: PropTypes.object,
  headline: PropTypes.string.isRequired,
  description: PropTypes.string
}

Main.defaultProps = {
  description: '',
}

export default Main
